import "./../App.css";
import React, { useState } from 'react';
import { QR } from "./../components/QR.js";
import { CompareStartDateThenTime } from "./../Methods.js";
import { useParams } from "react-router-dom";

export const SelfRegLinksEvent = ({ Back, state }) => {

    const [copyAlert,setCopyAlert] = useState(false);
    const { eventID } = useParams();

    return (
        <div>
            <button className="secondary" onClick={() => Back(true)}>Back</button>
            <section>
                <h1>{state.events[eventID].eventName} - Self-Registration Links</h1>
                {Object.values(state.activities).filter(item => item.eventID === eventID && state.events[item.eventID].selfReg).filter(item => item.open && item.selfReg).length > 0 ? Object.values(state.activities)
                    .filter(item => item.eventID === eventID && state.events[item.eventID].selfReg)
                    .filter(item => item.open && item.selfReg)
                    .sort(CompareStartDateThenTime)
                    .map((activityItem, activityIndex) => (
                        <div key={activityIndex} className="card qrGroup">
                            <div>
                                <QR passedURL={'self-register/' + activityItem.eventID + '/' + activityItem.activityGroupID + '/' + activityItem.activityID} />
                            </div>
                            <div className="qrSpacer" />
                            <div>
                                <h3 className="qrH3">{activityItem.activityName}</h3>
                                <p className="less">{activityItem.startDate.split("-").reverse().join("-")} | {activityItem.startTime} hrs</p>
                                <button className="hundred" onClick={() => { setCopyAlert(true); navigator.clipboard.writeText(window.location.origin + '/self-register/' + activityItem.eventID + '/' + activityItem.activityGroupID + '/' + activityItem.activityID ) }}>Copy URL</button>
                            </div>
                        </div>
                    )) : <p>No activities open for self-registration</p>}
            </section>
            {copyAlert && <div className="modal">
                <div>
                    <p>URL copied to your clipboard</p>
                    <button onClick={() => { setCopyAlert(false) }}>OK</button>
                </div>
            </div>}
        </div>
    )
}
