import "./../App.css";
import {MinsToStr,StrToMins} from "./../Methods.js";
import { useNavigate } from "react-router-dom";

export const SelfRegisterCard = ({eventItem,item,ViewPDF,state,translation}) => {
  const endTime = MinsToStr(StrToMins(item.duration) + StrToMins(item.startTime));
  const navigate = useNavigate();
  return (
    <div className={!item.open ? "card home red" : item.ticketsLeft > 0 ? "card home green" : "card home amber"}>
      <div className="justify">
        <h3 className="limit">{item.activityName}</h3>
        <div>
          <button className={!item.open ? "red" : item.ticketsLeft < 1 ? "amber" : "green"} disabled={!item.open} onClick={() => navigate("/self-register/" + item.eventID + "/" + item.activityGroupID + "/" + item.activityID)}>{!item.open ? translation[eventItem.language].closed : item.ticketsLeft < 1 ? translation[eventItem.language].joinWaiting : translation[eventItem.language].register}</button>
          {item.pdf && <button onClick={() => ViewPDF(item)}>{translation[eventItem.language].aboutActivity}</button>}
        </div>
      </div>
      <p className="comment"><i>{item.activityLocation}</i>{item.activityComment && " | "}<i>{item.activityComment}</i></p>
      <div className="justify">
        <h4>{item.startDate.split("-").reverse().join("-")} | {item.startTime}{translation[eventItem.language].hrs} - {endTime}{translation[eventItem.language].hrs} ({item.duration.replace(":","h")})</h4>
      </div>
    </div>
  )
}
