import "./../App.css";
import React, { useState } from 'react';
import {QR} from "./../components/QR.js";
import {CompareStartDate} from "./../Methods.js";
import { useNavigate } from "react-router-dom";

export const SelfRegLinks = ({Back,state}) => {

  const [copyAlert,setCopyAlert] = useState(false);
  const navigate = useNavigate();

  return (
    <div>
      <button className="secondary" onClick={() => Back(true)}>Back</button>
      <section>
        <h1>Event Self-Registration Links</h1>
        {Object.values(state.events)
          .filter(item => (state.role === "reception" || state.role === "manager") && state.receptionEventID ? item.eventID.toString() === state.receptionEventID.toString() : item)
          .filter(item => item.selfReg)
          .sort(CompareStartDate)
          .map((eventItem,eventIndex) => (
            <div key={eventIndex} className="card qrGroup">
              <div>
                <QR passedURL={'self-register/' + eventItem.eventID} />
              </div>
              <div className="qrSpacer"/>
              <div>
                <h3 className="qrH3">{eventItem.eventName}</h3>
                <p className="less">{eventItem.startDate.split("-").reverse().join("-")}</p>
                <button className="hundred" onClick={() => {setCopyAlert(true); navigator.clipboard.writeText(window.location.origin + '/self-register/' + eventItem.eventID)}}>Copy URL</button>
                <button className="hundred" onClick={() => navigate("/registration-links-event/" + eventItem.eventID)}>View activity links</button>
              </div>
            </div>
        ))}
      </section>
      {copyAlert && <div className="modal">
        <div>
          <p>URL copied to your clipboard</p>
          <button onClick={() => { setCopyAlert(false)}}>OK</button>
        </div>
      </div>}
    </div>
  )
}
