import React, { useEffect } from 'react';
import "./../App.css";
import { Card } from "./../components/Card.js";
import { CompareStartDate, CompareActivityName, CompareActivityGroupName, CompareStartDateThenTime } from "./../Methods.js";
import { useNavigate } from "react-router-dom";

export const HomePage = ({ state, handleChange }) => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  if (state.role === "reception") return (
    <section className="event">
      <h1>{state.events[state.receptionEventID].eventName}</h1>
      <div className='activityGroup'>
        <div className="select-wrapper auto"><select className="sort auto" value={state.sort} onChange={handleChange} name="sort">
          <option value="date">Sort by date</option>
          <option value="alpha">Sort alphabetically</option>
        </select></div>
        <div className="cards">
          {Object.values(state.activities).filter(item => item.eventID === state.receptionEventID).sort(state.sort === "date" ? CompareStartDateThenTime : CompareActivityName).map((item, index) => (
            <Card eventItem={state.events[state.receptionEventID]} key={index} index={index} item={item} state={state} />
          ))}
        </div>
      </div>
    </section>
  )
  return (
    <div>
      {/*<button onClick={() => TestQuery()}>Test button</button>*/}
      {Object.values(state.events)
        .filter(item => (state.role === "manager" && state.receptionEventID) ? item.eventID.toString() === state.receptionEventID.toString() : item)
        .sort(CompareStartDate)
        .map((eventItem, eventIndex) => (
          <section key={eventIndex}>
            <div className="eventHeader"><span className={eventItem.selfReg ? "dot green" : "dot red"}></span><span className="selfRegEdit semiBold" onClick={() => navigate("/edit-event/" + eventItem.eventID)}>Self-registration {eventItem.selfReg ? "open" : "closed"}</span></div>
            <div className="event">
              <div className="justify">
                <h1 className={(state.role === "super" || state.role === "manager") ? "limit" : null}>{eventItem.eventName}</h1>
                {(state.role === "super" || state.role === "manager") && <div className="firstButtons justifyMobile">
                  <button className="event edit home half" onClick={() => navigate("/edit-event/" + eventItem.eventID)}>Edit event</button>
                  <button className="event new home half" onClick={() => navigate("/new-activity-group/" + eventItem.eventID)}>New group <i className="fas fa-plus"></i></button>
                </div>}
              </div>
              {Object.values(state.activityGroups).filter(item => item.eventID === eventItem.eventID).sort(CompareActivityGroupName).map((activityGroupItem, activityGroupIndex) => (
                <div key={activityGroupIndex} className="activityGroup">
                  <div className="justify">
                    <p className={(state.role === "super" || state.role === "manager") ? "limit" : null}>{activityGroupItem.activityGroupName}</p>
                    {(state.role === "super" || state.role === "manager") && <div className='justifyMobile'>
                      <button className="group edit home half" onClick={() => navigate("/edit-activity-group/" + eventItem.eventID + "/" + activityGroupItem.activityGroupID)}>Edit group</button>
                      <button className="group new home half" onClick={() => navigate("/new-activity/" + eventItem.eventID + "/" + activityGroupItem.activityGroupID)}>New activity <i className="fas fa-plus"></i></button>
                    </div>}
                  </div>
                  {Object.values(state.activities).filter(item => item.activityGroupID === activityGroupItem.activityGroupID).length > 0 && <div className="select-wrapper auto"><select className="sort auto" value={state.sort} onChange={handleChange} name="sort">
                    <option value="date">Sort by date</option>
                    <option value="alpha">Sort alphabetically</option>
                  </select></div>}
                  <div className="cards">
                    {Object.values(state.activities).filter(item => item.activityGroupID === activityGroupItem.activityGroupID).sort(state.sort === "date" ? CompareStartDateThenTime : CompareActivityName).map((item, index) => (
                      <Card eventItem={eventItem} key={index} index={index} item={item} state={state} />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </section>
        ))}
    </div>
  )
}
